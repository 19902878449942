
function getDateString() {
    var options = {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    };

    var date = new Date();

    return date.toLocaleDateString("zu-ZA", options) + "-" + date.getMilliseconds();
}

function getLogText(oldText, newText) {
    let text = oldText;
    if ( String(oldText).length > 10000 ) {
        text = oldText.slice(5000);
    }

    let time = "[";
    time += new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
    time += "]: "

    if ( text === "" ) {
        return time + newText; 
    }
    else {
        return text + "\n" + time + newText;
    }
}


function generateTestSuiteName() {
    var fullDate = getDateString();
    fullDate = fullDate.replace(/\//gi, '-').replace(/,/gi, '_').replace(/:/gi, '-').replace(/ /gi, '');

    return fullDate;
}

function generateTestVerdictName() {
    var fullDate = getDateString();
    fullDate = fullDate.replace(/\//gi, '-').replace(/,/gi, '_').replace(/:/gi, '-').replace(/ /gi, '');

    return "Verdict-" + fullDate;
}

export { getLogText, generateTestSuiteName, generateTestVerdictName, getDateString }